import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "bg-image-masks.jpg" }) {
        publicURL
      }
    }
  `)

  const [showOverlay, setShowOverlay] = useState(true)

  useEffect(() => {
    window.wistiaInit = function(W) {
      var s = W.api("intro_video")
      s.bind("play", function() {
        setShowOverlay(false)
      })
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <SEO title="Home" />
      <section
        className="hero web-hero large-section thanks has-banner"
        style={{ backgroundImage: `url(${data.bgImage.publicURL})` }}
      >
        <div className="fadeOverlay"></div>
        <div className="inner fadeIn" data-wow-duration="1s">
          <h1>
            May 26-29 2020<br />in <span className="striked">Ottawa</span> The Interweb
            <br /><br />
            July 20-24 2020 in <span className="striked">SF</span> The Interweb
          </h1>

          <h2>
            <a className="what-is-forward" href="https://www.crowdcast.io/e/forwardjs-may-2020" target="_blank" rel="noopener noreferrer">
              Tickets for ForwardJS Ottawa
            </a>
            <br /><br />
            <a className="what-is-forward" href="http://bit.ly/forward2020cfp" target="_blank" rel="noopener noreferrer">
              Apply to Speak
            </a>
            <br /><br />
            <a className="what-is-forward" href="http://bit.ly/forwardjs-ottawa-sponsor" target="_blank" rel="noopener noreferrer">
              Sponsor ForwardJS Ottawa
            </a>
            <br /><br />
            <a className="what-is-forward" href="http://bit.ly/forwardjs-sf-sponsor" target="_blank" rel="noopener noreferrer">
              Sponsor ForwardJS San Francisco 
            </a>
          </h2>
        </div>
      </section>
      <section id="this-is-forward" className="video-section large-section">
        {showOverlay ? (
          <h3 className="video-overlay-header">This is Forward.</h3>
        ) : null}
        <div className="video-wrapper">
          <div
            id="intro_video"
            className="video wistia_embed wistia_async_ba84q1a3n8 videoFoam=true"
          >
            &nbsp;
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
